import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  post: '',
  image: null
};

export const BlogPost = createSlice({
  name: 'blogPost',
  initialState,

  reducers: {
    update_post: (state, action) => {
        state.post = action.payload;
    },

    add_image: (state, action) => {
        state.file = action.payload;
    }
  }
  
});

export const { update_post, add_image } = BlogPost.actions;

export const selectPost = (state) => state.blogPost.post;
export const selectImage = (state) => state.blogPost.file;

export default BlogPost.reducer;